import { Route, Switch } from 'react-router-dom';
import { Home } from '../app/index';
import PageNotFound from '../app/PageNotFound/PageNotFound';

export default function AppRoutes() {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="*" component={PageNotFound} />
    </Switch>
  );
}
